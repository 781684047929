.sidebar {
  z-index: 100;
  position: fixed;
  width: 90px;
  height: 100vh;
  background-color: #fafafa;
  transition-duration: .3s;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hamburger {
    width: 30px;
    margin-top: 20px;
    cursor: pointer;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
  }
}


.hamburger {
  fill: white;
  width: 30px;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 17.5px;

  path {
    fill: white;
  }
}

.sidebarOpen {
  z-index: 100;
  position: fixed;
  width: 100vw !important;
  height: 100vh !important;
  background-color: #fafafa;
  transition-duration: .2s;

  .linksContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 400px;
  }

  a{
    text-decoration: none;
  }

  .hamburger {
    width: 25px;
    margin-top: 20px;
    cursor: pointer;
    margin-left: 17.5px;
  }
}

