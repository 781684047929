

.text-outline-white:hover{
    color: white !important;
}


.menu:link{
    background-color: #1b8b98 !important;

}
.menu:visited{
    background-color: #19808c !important;
}

/*.imagen:hover {-webkit-transform: rotate(360deg);transform: rotate(360deg);}*/