$primary: #0076b5;
$danger: #ff4136;
$light: #AD0F06FF;
$success: #c00d03;
$info: #00d6cc;
@import "../../../node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar{
  width: 9px !important;
}

body::-webkit-scrollbar-thumb {
  width: 5px;
  background: $success ;
  border: 2px solid $light;
  border-radius: 40px 40px 40px 40px;


}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.carousel-caption {
  font-size: 8rem;
  position: absolute;
  right: 15% ;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 3.25rem;
  padding-bottom: 30.25rem;
  color: #fff;
  p{
    -webkit-text-stroke: 2px black;
  }

}
