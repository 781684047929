.imagen{
    border-radius:50% !important;
    -webkit-border-radius:50%;
    box-shadow: 0px 0px 15px 15px #c00d03;
    -webkit-box-shadow: 0px 0px 15px 15px #AD0F06FF;
    -webkit-transform: rotate(18000deg);
    transform:  rotate(18000deg);
    transition:all 30.3s ease-in-out;
}

.imagenx {
    border-radius:50% !important;
    -webkit-border-radius:50%;
    box-shadow: 0px 0px 15px 15px #1f99a8;
    -webkit-box-shadow: 0px 0px 15px 15px #1f99a8;
    -webkit-transform: rotate(360deg);
    transform:  rotate(360deg);
    transition:all 1.8s ease-in-out;

}


section#juntas div#fotos {
    display: none;
}
section#juntas div#fotos {
    position: fixed;
    background: #fff;
    display: block;
    width: 20%;
    height: auto;
    top: 15%;
    left: 5%;
    padding-bottom: 20px;
    border-radius: 7px;
    z-index: 900;
    box-shadow: 0px 0px 20px #000;
}
section#juntas div#fotos figure img {
    display: block;
    position: relative;
    width: 90%;
    height: auto;
    margin: auto;
    top: 10px;
    padding: 10px 0;
}
section#juntas div#fotos figure #cerrar {
    position: absolute;
    background: #fff;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-family: squareBold;
    font-size: 20px;
    padding-top: 3px;
    padding-right: 0px;
    box-shadow: 0px 0px 5px #000;
    color: #0b65ab;
}

.opacidad{
    transition: all 1s ease-out;
}